import { PriceBookItemQueryResponse } from '@/api/types/priceBookTypes'

export default class PriceBookItem {
  id: string
  name: string
  type: string
  defaultRate: number | null
  description: string | null

  constructor(json: PriceBookItemQueryResponse) {
    this.id = json.id
    this.name = json.name
    this.type = json.type
    this.defaultRate = json.default_rate
    this.description = json.description
  }
}
