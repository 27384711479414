import moment from 'moment'

export enum BatchPaymentTerms {
  Custom,
  DueOnReceipt,
  Net15,
  Net30,
  Net45,
  Net60,
  Net90,
  Net120,
}

export const PAYMENT_TERMS_OPTIONS = [
  {
    value: BatchPaymentTerms.Custom,
    text: 'Custom',
  },
  {
    value: BatchPaymentTerms.DueOnReceipt,
    text: 'Due on receipt',
  },
  {
    value: BatchPaymentTerms.Net15,
    text: 'Net 15',
  },
  {
    value: BatchPaymentTerms.Net30,
    text: 'Net 30',
  },
  {
    value: BatchPaymentTerms.Net45,
    text: 'Net 45',
  },
  {
    value: BatchPaymentTerms.Net60,
    text: 'Net 60',
  },
  {
    value: BatchPaymentTerms.Net90,
    text: 'Net 90',
  },
  {
    value: BatchPaymentTerms.Net120,
    text: 'Net 120',
  },
]

export enum BatchStatus {
  Batched = 'Batched',
  NotBatched = 'NotBatched',
}

export function batchPaymentTermsString(
  paymentTerms: BatchPaymentTerms
): string {
  if (paymentTerms === BatchPaymentTerms.Custom) return 'Custom'
  else if (paymentTerms === BatchPaymentTerms.DueOnReceipt)
    return 'Due on receipt'
  else if (paymentTerms === BatchPaymentTerms.Net15) return 'Net 15'
  else if (paymentTerms === BatchPaymentTerms.Net30) return 'Net 30'
  else if (paymentTerms === BatchPaymentTerms.Net45) return 'Net 45'
  else if (paymentTerms === BatchPaymentTerms.Net60) return 'Net 60'
  else if (paymentTerms === BatchPaymentTerms.Net90) return 'Net 90'
  else if (paymentTerms === BatchPaymentTerms.Net120) return 'Net 120'
  return 'Invalid payment terms'
}

export interface QbdSyncDetails {
  error?: string
  syncStatus: 'Success' | 'Failed'
  lastSyncedAt: string
  quickbooksTxnId?: string
  lastRunId: string
}

export function isQbdSyncDetails(details: unknown): details is QbdSyncDetails {
  return (
    typeof details === 'object' &&
    details !== null &&
    details !== undefined &&
    'syncStatus' in details &&
    'lastSyncedAt' in details &&
    'lastRunId' in details
  )
}

export interface SyncTarget {
  id: string
  name: string
  lastRequestedSyncAt: string | null
  details: unknown | QbdSyncDetails | null
  customerName: string | null
}

export interface SyncOption {
  id: string
  name: string
}

export default interface Invoice {
  id: string
  invoiceNumber: string | null
  invoiceDate: moment.Moment
  paymentTerms: BatchPaymentTerms
  dueDate: moment.Moment | null
  jobIds: string[]
  ownerId: string | null
  requestedJobCount: number
  assignedToServiceProviderJobCount: number
  scheduledJobCount: number
  activeJobCount: number
  pausedJobCount: number
  completedJobCount: number
  cancelledJobCount: number
  totalJobCount: number
  created: moment.Moment | null
  createdBy: string | null
  memo: string | null
  totalAmount: number | null
  hubId: string | null
  syncTargets: SyncTarget[]
  lastModified: moment.Moment
  hasLineItems: boolean
}

export interface InvoiceListItem {
  id: string
  invoiceNumber: string | null
  invoiceDate: moment.Moment
  paymentTerms: BatchPaymentTerms
  dueDate: moment.Moment | null
  jobIds: string[]
  ownerId: string | null
  clientName: string | null
  created: moment.Moment | null
  createdBy: string | null
  hubId: string | null
  hubName: string | null
}
