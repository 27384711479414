var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_setup.VsCard,{staticClass:"card"},[_c(_setup.VsWrapper,[(_setup.isManuallyRefetching)?_c('v-skeleton-loader',{attrs:{"type":"card-heading"}}):_c('div',{staticClass:"card-inner"},[_c('div',{staticClass:"sync-target-icon"},[_c(_setup.SyncTargetImages,{attrs:{"logo":_setup.logoName}})],1),_c('div',{staticClass:"flex-grow-1"},[_c(_setup.VsText,{attrs:{"type":"intro","bold":""}},[_vm._v(_vm._s(_vm.target.name)+" Sync")]),_c('p',{staticStyle:{"margin":"0.5em"}}),_c('v-row',[_c('v-col',{attrs:{"cols":"10"}},[_c(_setup.VsText,[_vm._v(_vm._s(_setup.statusText))])],1),(
              _setup.syncStatus === _setup.BatchSyncStatus.SyncSuccess ||
              _setup.syncStatus === _setup.BatchSyncStatus.SyncFailure
            )?_c('v-col',{attrs:{"cols":"2"}},[_c('v-icon',{attrs:{"color":_setup.syncStatus === _setup.BatchSyncStatus.SyncFailure
                  ? 'red'
                  : 'var(--color-blue)'}},[_vm._v(_vm._s(_setup.syncStatus === _setup.BatchSyncStatus.SyncFailure ? 'mdi-cloud-off-outline' : 'mdi-cloud-check-variant-outline'))])],1):_vm._e()],1),_c(_setup.VsText,{attrs:{"type":"warning"}},[_vm._v(_vm._s(_setup.syncFailureMessage))])],1)]),_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c(_setup.VsButton,{attrs:{"full-width":"","label":_setup.buttonLabel,"type":_setup.buttonStyleType,"disabled":!_setup.batchHasLineItems ||
            _setup.syncStatus === _setup.BatchSyncStatus.AwaitingInitialSync ||
            _setup.syncStatus === _setup.BatchSyncStatus.UpdatesPending},on:{"click":_setup.handleSyncClicked}})],1),(
          _setup.syncStatus === _setup.BatchSyncStatus.AwaitingInitialSync ||
          _setup.syncStatus === _setup.BatchSyncStatus.UpdatesPending
        )?_c('v-col',{attrs:{"cols":"auto"}},[_c(_setup.VsButton,{attrs:{"icon":"mdi-refresh","type":"secondary","disabled":_setup.isManuallyRefetching},on:{"click":_setup.manualRefetchSyncTargets}})],1):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }