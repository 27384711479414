import { useQuery } from '@tanstack/vue-query'
import { get } from '@/api/base'
import { PriceBookItemQueryResponse } from '@/api/types/priceBookTypes'
import PriceBookItem from '@/models/price-book-item'
import { sortBy } from 'lodash'
import { Pagination } from '@/api/types/pagination'

const v5Endpoint = '/api/v6/pricebook'

async function getAllPriceBookItemsQuery(
  offset = 0,
  limit = 100
): Promise<PriceBookItemQueryResponse[]> {
  const response = await get<Pagination<PriceBookItemQueryResponse>>(
    v5Endpoint,
    {
      params: { offset, limit },
    }
  )

  if (response.data.meta.current_page < response.data.meta.total_pages) {
    const nextOffset = offset + limit
    return [
      ...response.data.data,
      ...(await getAllPriceBookItemsQuery(nextOffset, limit)),
    ]
  }

  return response.data.data
}

export function getAllPriceBookItems() {
  return useQuery({
    queryKey: ['price-book-items'],
    queryFn: async () => {
      const priceBookItems = await getAllPriceBookItemsQuery()

      return sortBy(
        priceBookItems
          // do not cache archived items as we do not use them
          .filter((item) => !item.is_archived)
          .map((item) => new PriceBookItem(item)),
        (lineItem) => lineItem.name
      )
    },
    staleTime: Infinity,
  })
}
