import { computed, reactive, Ref, watch } from 'vue'
import { PickerItemType } from '@/components/vision/next/picker-list/models'
import {
  HeadlessAutoCompleteValueType,
  useAutoCompleteProvider,
  ValueResolver,
} from '@/components/vision/next/inputs/useAutoComplete'
import { useStore } from 'vuex-composition-helpers'
import User from '@/models/user'

type JobRequesterPickerOptions = {
  onNavigate?: () => void
  scrollToPosition?: (pos: number) => void
  valueResolver?: ValueResolver
  onValueChanged?: (item?: HeadlessAutoCompleteValueType) => void
  value: Ref<string | null>
  jobHubId: Ref<string>
}

export interface SearchRequesterOptions {
  searchText?: string | null
  jobHubId: string
  value: string | null | undefined
}

type State = {
  searchText: string
}

export const useJobRequesterPicker = (options: JobRequesterPickerOptions) => {
  const current = reactive<State>({
    searchText: '',
  })

  const search = computed(() => current.searchText)

  const autoComplete = useAutoCompleteProvider({
    search,
    onValueChanged: options.onValueChanged as any,
    value: options.value as any,
    valueResolver: options.valueResolver,
  })

  const searchOptions = computed<SearchRequesterOptions>(() => ({
    searchText: current.searchText,
    jobHubId: options.jobHubId.value,
    value: options.value.value,
  }))

  const { items, total } = useSearchJobRequester(searchOptions)

  // if the user is actively searching or changing
  // filters we should request to reset the scroll position
  watch(searchOptions, (newOpts, oldOpts) => {
    if (oldOpts.searchText !== newOpts.searchText) {
      options.scrollToPosition?.(0)
    }
  })

  const selectItem = (item: PickerItemType) => {
    autoComplete.addValue(item)
  }

  const updateSearch = (newSearch: string) => {
    current.searchText = newSearch
  }

  const clear = () => {
    autoComplete.clear()

    current.searchText = ''

    options.scrollToPosition?.(0)
  }

  const noResultsMessage = computed(() => {
    if (items.value.length > 0) {
      return
    }
    return 'No results'
  })

  const clearSearch = () => {
    current.searchText = ''
  }

  return {
    items,
    total,
    updateSearch,
    selectItem,
    clear,
    clearSearch,
    noResultsMessage,
  }
}

const useSearchJobRequester = (options: Ref<SearchRequesterOptions>) => {
  const store = useStore()
  const userBySid = computed<(userSid: string) => User>(
    () => store.getters['users/getBySid']
  )
  const getUsersRelatedToBusinessUnit = computed<(hubId: string) => User[]>(
    () => store.getters['users/getUsersRelatedToBusinessUnit']
  )
  const users = computed(() =>
    getUsersRelatedToBusinessUnit.value(options.value.jobHubId)
  )
  const customerArray = computed(() => {
    const validRoles = ['Customer', 'Dispatcher', 'Superadmin']
    const customerArrayWithoutDisabled = users.value.filter((u) =>
      u.roles.some((r) => validRoles.includes(r))
    )
    if (
      options.value.value &&
      !users.value.map((u) => u.id?.toString()).includes(options.value.value)
    ) {
      return customerArrayWithoutDisabled.concat(
        userBySid.value(options.value.value)
      )
    }
    return customerArrayWithoutDisabled
  })

  const total = computed(() => customerArray.value.length)

  const items = computed<Readonly<PickerItemType[]>>(() =>
    customerArray.value
      .map((c) => ({
        id: c.id,
        title: c.name ?? '',
        selectable: true,
      }))
      .filter((c) =>
        c.title
          .toLowerCase()
          .includes(options.value.searchText?.toLowerCase() ?? '')
      )
  )
  return { items, total }
}
