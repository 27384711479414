import { BatchPaymentTerms } from '@/models/invoice'
import moment from 'moment'

export function getDueDate(
  batchDate: moment.Moment,
  paymentTerms: BatchPaymentTerms
) {
  switch (paymentTerms) {
    case BatchPaymentTerms.Net15:
      return moment(batchDate).add(15, 'days')
    case BatchPaymentTerms.Net30:
      return moment(batchDate).add(30, 'days')
    case BatchPaymentTerms.Net45:
      return moment(batchDate).add(45, 'days')
    case BatchPaymentTerms.Net60:
      return moment(batchDate).add(60, 'days')
    case BatchPaymentTerms.Net90:
      return moment(batchDate).add(90, 'days')
    case BatchPaymentTerms.Net120:
      return moment(batchDate).add(120, 'days')
    default:
      return batchDate
  }
}
